/* eslint-disable react/prop-types */
import { graphql } from 'gatsby';
import * as React from 'react';
import ContactForm from '../components/Contact/ContactForm';
import HeaderContact from '../components/Contact/HeaderContact';
import { MainLayout } from '../components/MainLayout';
import { SEO } from '../components/SEO';
import routes from '../static/constants/routes';
import { seoData } from '../static/seoData';

const Kontakt = () => {
  return (
    <MainLayout>
      <HeaderContact />
      <ContactForm />
    </MainLayout>
  );
};

export default Kontakt;

export const Head = ({ pageContext }) => {
  const lang = pageContext.language;
  const metaData = seoData.contact[lang];

  return (
    <SEO
      title={metaData.title}
      description={metaData.description}
      pathname={routes.contact}
    />
  );
};

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["index"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
